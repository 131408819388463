import React from 'react'
import ReactDOM from 'react-dom'
import 'style/global.css'

import App from 'src/components/App'

const load = () => ReactDOM.render(<App />, document.body.appendChild(document.createElement('div')))

if (document.readyState != 'complete') {
  document.addEventListener('DOMContentLoaded', load)
} else {
  load()
}
