import React from 'react'

const TextInput = ({ className = '', error, ...props }) => (
  <div className={`flex flex-col ${className}`}>
    <input className={`border ${error ? 'border-red' : 'border-black-05 focus:border-black-25'} text-black-100 bg-gray focus:outline-none rounded px-4 py-2`} {...props} />
    {typeof error == 'string' && <div className='mt-2 text-red-100 text-sm'>{error}</div>}
  </div>
)

export default TextInput
