import React from 'react'
import logo from 'images/logo.svg'
import phones from 'images/phones.png'
import appleAppStoreQR from 'images/apple-app-store-qr.svg'
import appleAppStoreButton from 'images/apple-app-store-black-white.svg'
import googlePlayStoreQR from 'images/google-play-store-qr.svg'
import googlePlayStoreButton from 'images/google-play-store-black-white.svg'
import { iOS, android } from 'lib/utils'

const Lander = () => (
  <div className='w-screen min-h-screen bg-black-100 text-white-100'>
    <div className='flex flex-row justify-center py-37 mx-6 lg:mx-auto'>
      <div className='flex flex-col items-start max-w-100 lg:mr-25'>
        <img src={logo} className='h-8 mb-12' />
        <div className='text-3xl mb-14'>Download the VALT app</div>
        <div className='mb-14'>The simplest way to get professionally managed exposure to the rapidly growing universe of alternative assets.</div>
        <div className='flex flex-row items-center'>
        {!android &&
          <>
            <img src={appleAppStoreQR} className='mr-4' />
            <a href='https://apps.apple.com/us/app/valt-invest-alternatively/id1616550711' target='_blank' rel='nofollow'><img src={appleAppStoreButton} className='mr-8' /></a>
          </>
        }
        {!iOS &&
          <>
            <img src={googlePlayStoreQR} className='mr-4' />
            <a href='https://play.google.com/store/apps/details?id=com.vincent.valt' target='_blank' rel='nofollow'><img src={googlePlayStoreButton} /></a>
          </>
        }
        </div>
      </div>
      <div className='hidden lg:flex flex-col'>
        <img src={phones} className='w-107' />
      </div>
    </div>
  </div>
)

export default Lander
