import React from 'react'
import useInputState from 'hooks/useInputState'
import TextInput from 'components/input/TextInput'
import api from 'lib/api'
import logo from 'images/logo.svg'

const PASSWORD_REGEX = /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z1-9]).*$/

const PasswordReset = ({ buttonText = 'Reset Password' }) => {
  const [password, passwordState, { setError: setPasswordError }] = useInputState('')
  const [passwordConfirm, passwordConfirmState, { setError: setPasswordConfirmError }] = useInputState('')

  const onSubmit = async e => {
    e.preventDefault()
    setPasswordError(false)
    setPasswordConfirmError(false)
    if (!password.match(PASSWORD_REGEX)) {
      setPasswordError('minimum 8 characters, 1 digit and 1 symbol')
      return
    } else if (password != passwordConfirm) {
      setPasswordConfirmError('passwords do not match')
      return
    }

    const response = await api.postj('api/v1/users/password_reset', { password })
    if (response.ok) {
      window.location.href = window.location.origin
    } else {
      setPasswordError(true)
      setPasswordConfirmError(true)
    }
  }

  return (
    <form onSubmit={onSubmit} className='w-full h-screen bg-black-100 flex flex-col items-center justify-center text-white'>
      <img src={logo} className='h-8 mb-16' />
      <div className='text-lg mb-4 w-80'>Set your VALT password below.<br />This password will be used to sign in to the VALT app.</div>
      <TextInput name='password' type='password' placeholder='password' className='mb-4 w-80' {...passwordState} />
      <TextInput name='passwordConfirm' type='password' placeholder='password confirmation' className='mb-4 w-80' {...passwordConfirmState} />
      <button className='py-5 bg-yellow-100 text-black-100 rounded-full w-80'>{buttonText}</button>
    </form>
  )
}

export default PasswordReset
