import { useState } from 'react'

const useInputState = initial => {
  const [value, setValue] = useState(initial)
  const [error, setError] = useState(initial)
  return [
    value,
    {
      value,
      error,
      onChange: e => setValue(e.target.value),
      ...(initial && { defaultValue: initial })
    },
    {
      setValue,
      setError
    }
  ]
}

export default useInputState
