import Cookies from 'js-cookie'
import qs from 'qs'

const queryString = (query = {}) => !!Object.keys(query).length ? '?' + qs.stringify(query, {
  encode: false,
  arrayFormat: 'brackets'
}) : ''

const uri = (base, path, query = {}) => encodeURI(`${base}/${path.startsWith('/') ? path.substring(1, path.length) : path}${queryString(query)}`)

const applyMiddleware = (middleware, request) => middleware.reduce((request, plugin) => plugin(request), request)

class API {
  constructor({ base = window.location.origin, headers = {}, middleware = [] }) {
    this.base = base
    this.headers = headers
    this.middleware = middleware
  }

  async request(method, path, query = {}, body = '', headers = {}, signal) {
    let payload
    ({ path, query, ...payload } = applyMiddleware(this.middleware, {
      method,
      path,
      query,
      ...(body && { body }),
      headers: {
        ...this.headers,
        ...headers
      },
      signal
    }))
    return fetch(uri(this.base, path, query), payload)
  }

  async get(...args) {
    return await this.request('GET', ...args)
  }
  async post(...args) {
    return await this.request('POST', ...args)
  }
  async put(...args) {
    return await this.request('PUT', ...args)
  }
  async patch(...args) {
    return await this.request('PATCH', ...args)
  }
  async delete(...args) {
    return await this.request('DELETE', ...args)
  }

  async postj(path, json, query = {}, headers = {}, signal) {
    return await this.post(path, query, JSON.stringify(json), { 'Content-Type': 'application/json', ...headers }, signal)
  }
  async patchj(path, json, query = {}, headers = {}, signal) {
    return await this.patch(path, query, JSON.stringify(json), { 'Content-Type': 'application/json', ...headers }, signal)
  }
  async deletej(path, json, query = {}, headers = {}, signal) {
    return await this.delete(path, query, JSON.stringify(json), { 'Content-Type': 'application/json', ...headers }, signal)
  }

  async patchFile(path, file, query = {}, headers = {}, signal) {
    const formData = new FormData()
    formData.append('file', file)
    console.log('formData', formData, file);
    return await this.patch(path, query, formData, headers, signal)
  }
}

const csrf = request => {
  let token = Cookies.get('csrf')
  return { ...request, ...(token && { headers: { 'X-CSRF-TOKEN': token, ...request.headers } }) }
}

const api = new API({ headers: { 'Accept': 'application/json' }, middleware: [csrf] })
export default api
