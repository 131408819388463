import React from 'react'
import useInputState from 'hooks/useInputState'
import TextInput from 'components/input/TextInput'
import api from 'lib/api'

const Login = () => {
  const [email, emailState, { setError: setEmailError }] = useInputState('')
  const [password, passwordState, { setError: setPasswordError }] = useInputState('')

  const onSubmit = async e => {
    e.preventDefault()
    const response = await api.postj('login', { email, password })
    if (response.ok) {
      window.location.href = window.location.origin + '/admin'
    } else {
      setEmailError(true)
      setPasswordError(true)
    }
  }

  return (
    <form onSubmit={onSubmit} className='w-full h-screen flex flex-col items-center justify-center'>
      <TextInput name='email' type='email' placeholder='e@mail.com' className='mb-4 w-80' {...emailState} />
      <TextInput name='password' type='password' placeholder='P@ssw0rd' className='mb-4 w-80' {...passwordState} />
      <button className='py-5 bg-yellow-100 rounded-full w-80'>Sign In</button>
    </form>
  )
}

export default Login
