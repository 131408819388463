import React, { useEffect } from 'react'
import api from 'lib/api'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'

import Login from 'components/Login'
import PasswordReset from 'components/PasswordReset'
import Lander from 'components/Lander'


const App = () => (
  <Router>
    <Routes>
      <Route path='/' element={<Lander />} />
      <Route path='/login' element={<Login />} />
      <Route path='/password-reset' element={<PasswordReset />} />
      <Route path='/welcome' element={<PasswordReset buttonText='Set Password' />} />
      <Route path='*' element={<Lander />} />
    </Routes>
  </Router>
)

export default App