import { snakeCase } from 'lodash'

export const snakeKeys = object => Object.fromEntries(Object.entries(object).map(([k,v]) => [snakeCase(k), v]))

const IOS_NAVIGATOR_PLATFORMS = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
]

export const iOS = IOS_NAVIGATOR_PLATFORMS.includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

export const android = navigator.userAgent.toLowerCase().includes('android')
